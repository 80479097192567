
.main_what_is_ope {
    padding: 60px 0px;
}

.leftside_contant {
    h2 {
        font-size: 30px;
        line-height: 47px;
        font-family: 'Nasalization Rg';
        font-weight: 400;
        margin-bottom: 20px;
    }
    p {
        font-family: 'Nasalization Rg';
        font-weight: 400;
        color: rgba(0, 0, 0, 0.84);
        margin-bottom: 15px;
        font-size: 18px;
        line-height: 30px;
    }
}

.align_item_center {
    align-items: center;
}

.opeleftside_contant.leftside_contant {
    p.list_details {
        font-family: 'Nasalization Rg';
        font-weight: 400;
        color: rgba(0, 0, 0, 0.84);
        margin-bottom: 15px;
        font-size: 18px;
        line-height: 30px;
        position: relative;
        padding-left: 40px;
        span {
            position: absolute;
            left: 0px;
            top: -3px;
        }
    }
    .contantlist_titles {
        padding-left: 40px;
        p {
            font-size: 16px;
            line-height: 24px;
            color: #000000;
            margin-bottom: 8px;
            padding-left: 15px;
            position: relative;
            font-family: 'Nasalization Rg';
            font-weight: 400;
            &:before {
                content: "";
                position: absolute;
                width: 6px;
                height: 6px;
                background: rgba(0, 0, 0, 0.84);
                border-radius: 8px;
                left: 0px;
                top: 10px;
            }
        }
    }
   
}



.align-right {
    text-align: right;
}
.imageRightsideDetail {
    animation: moverleftright 2s infinite alternate;
}
.padding_ope {
    padding: 80px 0px;
}

.main_whatcan {
    padding: 80px 0px;
    position: relative;
    .leftside_contant {
        h3 {
            font-size: 18px;
            line-height: 28px;
            color: #000000;
            font-family: 'Nasalization Rg';
            font-weight: 500;
            margin-top: 20px;
        }
        
    }
    .whatcanlisting {
        p {
            font-family: 'Nasalization Rg';
            font-weight: 400;
            color: rgba(0, 0, 0, 0.84);
            margin-bottom: 15px;
            font-size: 18px;
            line-height: 30px;
            position: relative;
            padding-left: 40px;
            span {
                position: absolute;
                left: 0px;
                top: -2px;
            }
        }
    }
}
.main_whatcan:before {
    content: "";
    position: absolute;
    left: 0px;
    width: 910px;
    height: 100%;
    z-index: -1;
    background-image: url(../../Static/circel-bg.svg);
    background-repeat: no-repeat;
    background-size: 630px;
    top: -100px;
}

.main_migration {
    .Center_heading {
        h2 {
            margin: 0px auto;
            margin-bottom: 20px;
            max-width: 812px;
        }
    }
    .migrationImageList {
        ul {
            list-style: none;
            padding: 0;
            li {
                width: 31%;
                border: 1px solid #ccc;
                margin: 10px 10px 10px 10px;
                padding: 5px;
                border-radius: 4px;
                height: 210px;
                vertical-align: middle;
                align-items: center;
                justify-content: center;
                display: inline-flex;
                flex-wrap: wrap;
                flex: 0 0 30%;
                img {
                    height: 150px;
                    width: 100%;
                    object-fit: contain;
                }
            }
        }
    }
}
.main_migration .migrationImageList ul li:first-child {
    margin-left: 10px;
}


@media (max-width:990px) {
    .main_whatcan {
        padding: 40px 0px;
    }
    .main_whatcan:before {
        display: none;
    }
    .main_migration .migrationImageList ul li {
        margin: 10px 10px;
    }
    .main_migration .migrationImageList ul li:first-child {
        margin: 10px 10px;
    }
    .leftside_contant {
        h2 {
            font-size: 30px;
            line-height: 36px;
        }
    }
}

@media only screen and  (min-width:768px) {
    .serviceLeft{
        .Info_serv {
            order: 1;
        }
    }
}

@media only screen and  (max-width:768px) {
 .services_page{
    padding-top: 50px;
    padding-bottom: 0;
    .serviceLeft {
        padding-top: 10px;
    }
    .imageRightsideDetail {
        text-align: center;
        margin-top: 30px;
    }
    h2 {
        font-size: 24px;
    }
    p {
        font-size: 16px;
        line-height: 26px;
    }
    
 }
 .main_migration{
    margin-top: 50px;
    .migrationImageList{
        ul{
            li {
                width: 45.5%;
                padding: 20px;
            }
        }
    }
    h2 {
        font-size: 30px;
    }
 }
 .services_head_section {
    h2{
        font-size: 30px;
    }
 }
 .services_head_sec-section{
    margin-top: 35px;
    p {
        margin-bottom: 0;
    }
    h2 {
        font-size: 30px;
    }
    
 }
}
@media only screen and  (max-width:500px) {
    .main_migration{
        .migrationImageList{
            ul{
                li{
                    width: 100%;
                    margin: 0;
                    margin-bottom: 30px;
                    &:first-child{
                        margin: 0;
                        margin-bottom: 30px;
                    }
                }
                
            }
        }
    }
}