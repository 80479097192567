.Pricing_main {
    // background-image: url(../../Static/bg-faq.png);
    background-position: 50% 80px;
    background-repeat: no-repeat;
    padding-bottom: 150px;
    padding-top: 60px;
    &:before {
        content: "";
        -webkit-animation: spinAround 80s linear infinite;
        -moz-animation: spinAround 80s linear infinite;
        animation: spinAround 80s linear infinite;
        background-image: url(../../Static/bg-faq2.svg);
        background-repeat: no-repeat;
        width: 630px;
        background-size: contain;
        height: 600px;
        position: absolute;
        background-position: center;
        z-index: -1;
        margin: 0px auto;
        right: 0px;
        left: 0px;
    }
    .Pricing_box_main {
        background: #FAFBFC;
        border-radius: 20px;
        padding: 20px;
        h4 {
            text-align: center;
            font-size: 14px;
            line-height: 30px;
            text-transform: uppercase;
            color: rgba(0, 0, 0, 0.54);
        } 
        .btn_buynow {
            background: linear-gradient(90deg, #25AAE1 0%, #0481EB 46.35%, #3F86ED 73.44%, #4481EB 100%);
            border-radius: 4px;
            width: 100%;
            height: 46px;
            font-family: 'Nasalization Rg';
            font-weight: 700;
            padding: 0px;
            margin-top: 40px;
        }
        .startingprice_text {
            text-align: center;
            min-height: 100px;
            h5 {
                font-size: 14px;
                text-align: center;
                color: rgba(0, 0, 0, 0.54);
                margin-bottom: 0px;
                height: 20px;
            }
            h2 {
                font-size: 58px;
                line-height: 68px;
                text-align: center;
                color: rgba(0, 0, 0, 0.84);
                font-family: 'Nasalization Rg';
                font-weight: 500;
                margin-bottom: 0px;
                
            }
        }
        .listofplan_pricing {
            margin-top: 30px;
            p {
                font-size: 14px;
                line-height: 19px;
                color: rgba(0, 0, 0, 0.54);
                span {
                    float: right;
                    color: rgba(0, 0, 0, 0.84);
                    font-size: 16px;
                    line-height: 23px;
                    font-family: 'Nasalization Rg';
                    font-weight: 500;
                }
              }
        }
    }
    
    .Pricing_box_main.Basic_plan {
        background: #EFF6FC;
    }
    .Pricing_box_main.Professional_plan {
        background: #DEECF9;
    }
    .Pricing_box_main.Enterprise_plan {
        background: linear-gradient(90deg, #25AAE1 0%, #0481EB 46.35%, #3F86ED 73.44%, #4481EB 100%);
        h4 {
            color: rgba(255, 255, 255, 0.84);
         }
         .btn_buynow {
            color: #0078D4; 
            background: #FFFFFF;
         }
         .startingprice_text {
            h5 {
                color: rgba(255, 255, 255, 0.84);
             }
             h2 {
                color: rgba(255, 255, 255, 0.84);
             }
         }
         .listofplan_pricing {
            p {
                color: rgba(255, 255, 255, 0.84);
                span {
                    color: rgba(255, 255, 255, 0.84);
                }
             }
         }
    
    }
}

.pricingpage_bottomdetail {
    padding-top: 120px;
    h3 {
        font-family: 'Nasalization Rg';
        font-weight: 400;
        font-size: 30px;
        line-height: 30px;
        text-align: center;
        color: #000000;
        margin-bottom: 20px;
    }
    h4 {
        font-family: 'Nasalization Rg';
        font-weight: 400;
        font-size: 40px;
        line-height: 48px;
        text-align: center;
        color: #0078D4;
    }
}

@media (max-width:990px){
    .Pricing_main {
        padding-bottom: 60px;
        .Pricing_box_main {
            margin-bottom: 20px;
        }
    }
}
@media only screen and  (max-width:768px) {
.Pricing_main:before{
    display: none;
}
.services_head_section{
    h2 {
        font-size: 30px;
    }
}

.pricingpage_bottomdetail{
    h4 {
        font-size: 24px;
    }
}

.pricingpage_bottomdetail{
    h3 {
        font-size: 24px;
    }
}
.Pricing_main {
    .Center_heading {
        p {
            font-size: 30px;
            line-height: 40px;
            font-weight: 900;
        }
    }
}
}
