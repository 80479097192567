

.Makinglist_box {
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    border-radius: 10px;
    padding: 32px 16px;
    display: flex;
    margin-top: 24px;
   
    &:hover {
        box-shadow: 0px 0px 40px #c4c4c4;
        background: #0078D4;

    }
    &:hover .ContantMakinglist h3 {
        color: #fff;
    }
    &:hover .ContantMakinglist p {
        color: rgba(255, 255, 255, 0.84);
    }
    &:hover .IconMakinglist {
        background: #fff;
        transform: rotate(360deg);
    }
    
    .IconMakinglist {
        background: #DEECF9;
        width: 80px;
        height: 80px;
        text-align: center;
        margin-right: 20px;
        border-radius: 50%;
        flex: 0 0 80px;
        align-items: center;
        display: flex;
        justify-content: center;
        border: 1px dashed #deecf9;
        transition: 0.9s;
    }

    .ContantMakinglist {
        display: inline-block;
        h3 {
            font-size: 24px;
            line-height: 30px;
            color: #000000;
             font-family: 'Nasalization Rg';
        }
        p {
             font-family: 'Nasalization Rg';
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: rgba(0, 0, 0, 0.84);
        }
    }
}



.whatmakesleftimage {
    position: relative;
    .topImageWhat {
        position: absolute;
        top: -100px;
        z-index: 9;
    }
    .middleImageWhat {
        position: relative;
        z-index: 0;
    }
    .bottamImageWhat {
        position: absolute;
        bottom: -100px;
        z-index: -1;
    }
    img  {
        width: 100%;
    }
}

.align-item-center {
    align-items: center;
}


@media (max-width:767px) {
    .leftimageWidth {
        img {
            width: 100%;
        }
    }
    .Makinglist_box {
        padding: 10px 10px;
        margin-top: 15px;
        .IconMakinglist {
            width: 60px;
            height: 60px;
            flex: 0 0 60px;
        }
    }
}