
.FooterMainSection {
    background-color: #004578;
    color: #fff;
    margin-top: 120px;
    padding-top: 70px;
    position: relative;
    &:before {
        // content: "";
        // background-image: url(../../Static/Circel_animation.svg);
        // width: 460px;
        // height: 460px;
        // position: absolute;
        // top: -360px;
        // right: -190px;
        // background-size: 100%;
        // background-repeat: no-repeat;
        // z-index: 0;
    }
    .footer_bg_image {
        position: absolute;
        width: 100%;
        top: -180px;
        z-index: -1;
        img {
            width: 100%;
        }
    }
    a {
        font-size: 14px;
        font-family: 'Nasalization Rg';
        font-weight: 400;
        color: #fff;
        text-decoration: none;
        transition: all 0.3s;
        &:hover {
            color: #fff;
            margin-left: 10px;
        }
    }
    ul {
        padding: 0;
        li {
            list-style: none;
            line-height: 30px;
        }
    }
    .Condition_links {
        padding: 0;
        margin-bottom: 0px !important;
        display: inline-block;
        li {
            list-style: none;
            line-height: 30px;
             margin-right: 50px;
             display: inline-block;
             a {
                opacity: 0.8;
                &:hover {
                    color: #fff;
                    opacity: 1 !important;
                    margin-left: 0px;
                }
             }
        }
    }
    .Copyright_footer {
        text-align: right;
        font-size: 13px;
        opacity: 0.8;
    }
    .Copyright_main_footer {
        margin-top: 10px;
        padding-bottom: 20px;
    }
    h3 {
        font-size: 16px;
        line-height: 19px;
        color: #FFFFFF;
    }

    .footer_details {
        border-top: 1px solid rgb(255 255 255 / 20%);
        border-bottom: 1px solid rgb(255 255 255 / 20%);
        padding-top: 30px;
        margin-top: 40px;
        .footer_logo {
            flex: 0 0 150px;
            margin-right: 100px;
        }
        p {
            font-size: 13px;
            line-height: 20px;
            opacity: 0.8;
            font-family: 'Nasalization Rg';
            font-weight: 400;
            margin-bottom: 30px;
        }
    }

}

@media (max-width:767px) {
   
    .FooterMainSection {
        padding-top: 20px;
        h3 {
            margin-top: 30px;
        }
        .footer_details .footer_logo {
            margin-bottom: 30px;
            flex: 0 0 100%;
        }
        .footer_bg_image {
            top: -78px;
        }
        .Copyright_footer {
            text-align: left;
            margin-top: 10px;
        }
    }
}