.Portfolio_mainsection {
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    align-items: center;
    width: 80%;
    margin: 0px auto 30px auto;
    &:hover {
        background-color: #fbfdff;
    }
    .Portfolio_inner_image {
        width: auto;
    display: inline-block;
    background-color: #DEECF9;
    text-align: center;
    padding: 20px;
    height: 250px;
    min-width: 260px;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    }
    
    .Portfolio_inner_content {
        display: inline-block;
        margin-left: 60px;
        .btn.btn-readmore {
            background: linear-gradient(90deg, #25AAE1 0%, #0481EB 46.35%, #3F86ED 73.44%, #4481EB 100%);
            border-radius: 4px;
            height: 46px;
            line-height: 46px;
            padding: 0px 16px;
            border: 0px;
            font-size: 16px;
            font-family: "Nasalization Rg";
            font-weight: 400;
            color: #fff;
        }
    }

}



@media (max-width:767px) {
    .Portfolio_mainsection {
        flex-wrap: wrap;
        width: 100%;
    }
    
    .Portfolio_mainsection .Portfolio_inner_image {
        min-width: 240px;
        width: 100%;
    }
    
    .Portfolio_mainsection .Portfolio_inner_content {
        margin-left: 0px;
        margin-top: 20px;
    }
}