/*@font-face {
    font-family: 'litera';
    src: url('./Static/fonts/litera-regular.eot') format('eot'),
         url('./Static/fonts/litera-regular.woff2') format('woff2'),
         url('./Static/fonts/litera-regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'litera';
    src: url('./Static/fonts/litera-heavy.eot') format('eot'),
         url('./Static/fonts/litera-heavy.woff2') format('woff2'),
         url('./Static/fonts/litera-heavy.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'litera';
    src: url('./Static/fonts/litera-bold.eot') format('eot'),
         url('./Static/fonts/litera-bold.woff2') format('woff2'),
         url('./Static/fonts/litera-bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
*/
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;900&family=Roboto:wght@300;400;500;700;900&display=swap');


@font-face {
    font-family: 'Nasalization Rg';
    src: url('./Static/fonts/NasalizationRg-Regular.woff2') format('woff2'),
        url('./Static/fonts/NasalizationRg-Regular.woff') format('woff'),
        url('./Static/fonts/NasalizationRg-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}



h1, h2, h3, h4, h5, ol, ul, li, p {
    font-family: 'Nasalization Rg';
}

body {
    font-family: 'Nasalization Rg';
    overflow-x: hidden;
}