.Contactpage_main {
    margin-top: 60px;
    padding-bottom: 100px;
    .Contactform_box {
        filter: drop-shadow(-4px 6px 24px rgba(0, 0, 0, 0.1));
        background: #FAFBFC;
        background: #FAFBFC;
        max-width: 90%;
        margin: 10px auto 0px;
        padding: 60px;
        border-radius: 20px;
        .Image_animation {
            animation: moverleftright 2s infinite alternate;
            img {
                width: 100%;
            }
        }
        label {
            font-size: 14px;
            line-height: 17px;
            color: rgba(0, 0, 0, 0.87);
            margin-bottom: 10px;
            font-family: 'Nasalization Rg';
            font-weight: 400;
        }
        input.form-control {
            background-color: #fff;
            border: 1px solid #E8E9EB;
            height: 40px;
            font-family: 'Nasalization Rg';
            font-weight: 400;
            padding: 0px 10px;
            border-radius: 4px;
            font-size: 14px;
            width: 100%;
        }
        .account_btn {
            background: linear-gradient(90deg, #25AAE1 0%, #0481EB 46.35%, #3F86ED 73.44%, #4481EB 100%);
            border-radius: 4px;
            height: 46px;
            line-height: 46px;
            padding: 0px 16px;
            border: 0px;
            font-size: 16px;
            font-family: 'Nasalization Rg';
            font-weight: 500;
            width: 100%;
            margin-top: 20px;
        }
    }
    .errormessgae {
        color: red;
        font-size: 14px;
    }
  
    .Thankyoumessage {
        color: #71afe5;
        background-color: transparent;
        height: 60px;
        line-height: 60px;
        padding: 0px;
        align-items: center;
        display: flex;
        justify-content: center;
        border-radius: 5px;
        position: relative;
        top: -30px;
        border: 3px dashed #71afe5;
        h4 {
            margin: 0px;
        }
    }
        
}

@-webkit-keyframes moverleftright {
    0% { transform: translateX(0); }
    100% { transform: translateX(-20px); }
}
@keyframes moverleftright {
    0% { transform: translateX(0); }
    100% { transform: translateX(-20px); }
}

@media (max-width:767px) {
    .Contactpage_main { 
    padding-bottom: 30px;
    .Contactform_box {
        padding:30px 15px;
        max-width: 100%;
    }
    .Image_animation{
        img{
            max-width: 250px;
            margin: 0 auto;
            display: table;
        }
    }
}
}